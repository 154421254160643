import $ from "jquery";
import store from "@/state/store.js";
import messageModal from "../messages/messageModal.vue";
import moment from "moment";
export default {
  name: "api-v2",
  components: {
    messageModal
  },
  data() {
    return {
      showApiKey: false,
      loading: false,
      createError: "",
      success: "",
      type: "alert-danger",
      error: "",
      formData: {
        project_name: "",
        usage_limit: "",
        limit: ""
      },
      from: "",
      dataPayload: {},
      apiKeys: [],
      enabled: "",
      selecctedId: "",
      selectedKey: "",
      selectedStatus: 0,
      toggleStatusError: "",
      toggleLoading: false,
      rules: {
        limit: [{
          required: true,
          message: "Please enter usage limit.",
          trigger: ["change", "blur"]
        }],
        usage_limit: [{
          required: true,
          message: "Please enter usage limit.",
          trigger: ["change", "blur"]
        }],
        project_name: [{
          required: true,
          message: "Please enter project name.",
          trigger: ["change", "blur"]
        }]
      }
    };
  },
  methods: {
    hideToggleModal() {
      this.formData.limit = "";
      this.success = "";
      $("#togglestatus").modal("hide");
    },
    toggleStatus() {
      this.toggleLoading = true;
      this.toggleStatusError = "";
      if (this.selectedStatus == 0) {
        return this.enabledStatus();
      }
      return this.disabledStatus();
    },
    enabledStatus() {
      store.dispatch("developer/enableApiV2", this.selecctedId).then(data => {
        this.apiKeys[this.selectedKey].status = 1;
        this.apiKeys = [...this.apiKeys];
        this.success = data.message;
      }).catch(err => {
        if (err.response.data == undefined) {
          this.toggleStatusError = "An error occured try again.";
        }
        if (err.response && err.response.status === 404) {
          this.toggleStatusError = err.response.data.data;
        }
      }).finally(() => this.toggleLoading = false);
    },
    disabledStatus() {
      store.dispatch("developer/disableApiV2", this.selecctedId).then(data => {
        this.apiKeys[this.selectedKey].status = 0;
        this.apiKeys = [...this.apiKeys];
        this.success = data.message;
      }).catch(err => {
        if (err.response.data == undefined) {
          this.toggleStatusError = "An error occured try again.";
        }
        if (err.response && err.response.status === 404) {
          this.toggleStatusError = err.response.data.data;
        }
      }).finally(() => this.toggleLoading = false);
    },
    handleStatus({
      key,
      data
    }) {
      this.success = false;
      this.selectedKey = key;
      this.selectedStatus = data.status;
      this.selecctedId = data.id;
      $("#togglestatus").modal("show");
    },
    addLimit({
      key,
      data
    }) {
      this.selectedKey = key;
      this.selecctedId = data.id;
      this.formData.limit = "";
      this.success = "";
      $("#deletecontact").modal("show");
    },
    hideModal() {
      this.formData.limit = "";
      this.success = "";
      $("#deletecontact").modal("hide");
    },
    save(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.loading = true;
          this.error = "";
          store.dispatch("developer/addLimitApiV2", {
            id: this.selecctedId,
            body: this.formData
          }).then(data => {
            this.apiKeys[this.selectedKey].usage_limit = this.formData.limit;
            this.apiKeys = [...this.apiKeys];
            this.success = data.message;
          }).catch(err => {
            if (err.response.data == undefined) {
              this.error = "An error occured try again.";
            }
            if (err.response && err.response.status === 404) {
              this.error = err.response.data.data;
            }
          }).finally(() => this.loading = false);
        } else {
          this.loading = false;
          return false;
        }
      });
    },
    deleteSuccess(data) {
      this.apiKeys.splice(this.dataPayload.selectedKey, 1);
    },
    opendModal({
      message,
      key
    }) {
      this.dataPayload = {
        currentModal: "delete",
        selectedKey: key,
        selectedMessage: message,
        deleteType: "_deleteApiV2Key",
        deleteMessage: "This will remove the Api Key permanently."
      };
      store.dispatch("uistate/addContactFormData", {
        title: "",
        type: "delete"
      });
      let element = this.$refs.messageModal.$el;
      $(element).modal("show");
    },
    async getApiV2() {
      const data = await store.dispatch("developer/getApiV2");
      this.apiKeys = data;
    },
    submit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.loading = true;
          this.createError = "";
          store.dispatch("developer/createApiV2", this.formData).then(data => {
            window.location.reload();
          }).catch(err => {
            if (err.response.data == undefined) {
              this.createError = "An error occured try again.";
            }
            if (err.response && err.response.status === 404) {
              this.createError = err.response.data.data;
            }
          }).finally(() => this.loading = false);
        } else {
          this.loading = false;
          return false;
        }
      });
    }
  },
  mounted() {
    this.getApiV2();
  }
};