var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "mnotify-card"
  }, [_vm._m(0), _c('div', {}, [_c('div', {
    staticClass: "campaigne-card-inner"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('button', {
    staticClass: "create_api_key",
    on: {
      "click": function ($event) {
        _vm.showApiKey = true;
      }
    }
  }, [_vm._v(" Create API Key ")])]), _vm.showApiKey ? _c('el-dialog', {
    staticClass: "show-api-container",
    attrs: {
      "title": "Create API Key v.2.0",
      "visible": _vm.showApiKey
    },
    on: {
      "update:visible": function ($event) {
        _vm.showApiKey = $event;
      }
    }
  }, [_c('el-form', {
    ref: "ruleForm",
    staticClass: "main-form",
    attrs: {
      "model": _vm.formData,
      "rules": _vm.rules
    }
  }, [_c('div', {
    staticClass: "form_input_container my-5"
  }, [_c('el-form-item', {
    attrs: {
      "prop": "project_name",
      "label": "Project Name"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "text",
      "placeholder": "Enter name of project associated with this API key.",
      "autocomplete": "false"
    },
    model: {
      value: _vm.formData.project_name,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "project_name", $$v);
      },
      expression: "formData.project_name"
    }
  })], 1)], 1), _c('div', {
    staticClass: "form_input_container"
  }, [_c('el-form-item', {
    attrs: {
      "prop": "usage_limit",
      "label": "Usage Limit"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "text",
      "placeholder": "Please enter usage limit.",
      "autocomplete": "false"
    },
    model: {
      value: _vm.formData.usage_limit,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "usage_limit", $$v);
      },
      expression: "formData.usage_limit"
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-"
  }, [_vm.createError ? _c('Alert', {
    attrs: {
      "message": _vm.createError,
      "type": _vm.type
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "col-md-12 _buttons"
  }, [_c('button', {
    staticClass: "_cancel",
    on: {
      "click": function ($event) {
        _vm.showApiKey = false;
      }
    }
  }, [_vm._v(" Cancel ")]), _c('el-button', {
    staticClass: "create_api_key",
    attrs: {
      "loading": _vm.loading
    },
    on: {
      "click": function ($event) {
        return _vm.submit('ruleForm');
      }
    }
  }, [_vm._v(" Create API Key ")])], 1)])], 1) : _vm._e(), _vm.apiKeys.length ? _c('div', {
    staticClass: "__reporting__table"
  }, [_c('table', {
    staticClass: "__mnotify__table"
  }, [_vm._m(1), _c('tbody', {
    staticClass: "__mnotify__table__body"
  }, _vm._l(_vm.apiKeys, function (j, key) {
    return _c('tr', {
      key: key,
      staticClass: "__table__with__border"
    }, [_c('td', {
      staticClass: "mnotify__table__first__item",
      staticStyle: {
        "cursor": "pointer"
      }
    }, [_vm._v(" " + _vm._s(j.project_name) + " ")]), _c('td', [_vm._v(" " + _vm._s(j.key) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm.moment(j.created_at).format("llll")) + " ")]), _c('td', {
      class: [j.status == 0 ? 'disabled' : 'enabled']
    }, [_vm._v(" " + _vm._s(j.status == 0 ? "disabled" : "enabled") + " ")]), _c('td', [_vm._v(" " + _vm._s(j.usage_limit) + " ")]), _c('td', [_c('div', {
      staticClass: "_stat"
    }, [_c('div', {
      staticClass: "known_actions",
      staticStyle: {
        "display": "flex",
        "gap": "0.5rem"
      }
    }, [_c('button', {
      staticClass: "delete",
      on: {
        "click": function ($event) {
          return _vm.addLimit({
            key: key,
            data: j
          });
        }
      }
    }, [_c('img', {
      attrs: {
        "width": "20px",
        "height": "20px",
        "src": require("@/assets/Edit.svg"),
        "alt": "edit icon"
      }
    })]), _c('button', {
      staticClass: "delete",
      on: {
        "click": function ($event) {
          return _vm.opendModal({
            message: j,
            key: key
          });
        }
      }
    }, [_c('img', {
      attrs: {
        "width": "20px",
        "height": "20px",
        "src": require("@/assets/__delete.svg"),
        "alt": "bin icon for delete"
      }
    })])]), _c('el-switch', {
      attrs: {
        "value": j.status == 0 ? false : true,
        "active-color": "#13ce66",
        "inactive-color": "#ff4949"
      },
      on: {
        "change": function ($event) {
          return _vm.handleStatus({
            key: key,
            data: j
          });
        }
      }
    })], 1)])]);
  }), 0)])]) : _vm._e(), _vm._m(2)], 1)])])])])])]), _c('messageModal', {
    ref: "messageModal",
    attrs: {
      "payload": _vm.dataPayload,
      "from": _vm.from
    },
    on: {
      "deleteSuccess": _vm.deleteSuccess
    }
  }), _c('div', {
    staticClass: "modal fade",
    attrs: {
      "id": "deletecontact",
      "tabindex": "-1",
      "role": "dialog",
      "aria-labelledby": "contact",
      "aria-hidden": "true"
    }
  }, [_c('div', {
    staticClass: "modal-dialog modal-dialog-centered",
    attrs: {
      "role": "document"
    }
  }, [_vm.success ? _c('div', {
    staticClass: "modal-content d-flex align-items-center"
  }, [_c('successMessage', {
    attrs: {
      "message": _vm.success
    }
  }), _c('button', {
    staticClass: "__close__success__modal",
    on: {
      "click": _vm.hideModal
    }
  }, [_vm._v(" Close ")])], 1) : _vm._e(), !_vm.success ? _c('div', {
    staticClass: "modal-content pb-3"
  }, [_c('div', {
    staticClass: "modal-body m-0 p-0"
  }, [_vm._m(3), _c('div', {
    staticClass: "mt-3"
  }, [_c('div', {
    staticClass: "col-md-12 mb-2"
  }, [_c('el-form', {
    ref: "ruleForm",
    attrs: {
      "model": _vm.formData,
      "rules": _vm.rules
    },
    nativeOn: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.checkEnter.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "form_input_container"
  }, [_c('el-form-item', {
    attrs: {
      "label": "Usage Limit",
      "prop": "limit",
      "required": ""
    }
  }, [_c('el-input', {
    attrs: {
      "type": "text",
      "autocomplete": "off",
      "placeholder": "Enter usage limit"
    },
    model: {
      value: _vm.formData.limit,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "limit", $$v);
      },
      expression: "formData.limit"
    }
  })], 1)], 1)])])])], 1), _c('div', {
    staticClass: "col-md-12"
  }, [_vm.error ? _c('Alert', {
    attrs: {
      "message": _vm.error,
      "type": _vm.type
    }
  }) : _vm._e()], 1)]), _c('div', {
    staticClass: "modal__footer__btns d-flex justify-content-between mx-3"
  }, [_c('button', {
    staticClass: "cancel",
    staticStyle: {
      "width": "123px"
    },
    on: {
      "click": _vm.hideModal
    }
  }, [_vm._v(" Cancel ")]), _c('el-button', {
    staticStyle: {
      "width": "123px"
    },
    attrs: {
      "type": "primary",
      "loading": _vm.loading,
      "id": "cancelinput"
    },
    on: {
      "click": function ($event) {
        return _vm.save('ruleForm');
      }
    }
  }, [_vm._v("Add Limit")])], 1)])]) : _vm._e()])]), _c('div', {
    staticClass: "modal fade",
    attrs: {
      "id": "togglestatus",
      "tabindex": "-1",
      "role": "dialog",
      "aria-labelledby": "contact",
      "aria-hidden": "true"
    }
  }, [_c('div', {
    staticClass: "modal-dialog modal-dialog-centered",
    attrs: {
      "role": "document"
    }
  }, [_vm.success ? _c('div', {
    staticClass: "modal-content d-flex align-items-center"
  }, [_c('successMessage', {
    attrs: {
      "message": _vm.success
    }
  }), _c('button', {
    staticClass: "__close__success__modal",
    on: {
      "click": _vm.hideToggleModal
    }
  }, [_vm._v(" Close ")])], 1) : _vm._e(), !_vm.success ? _c('div', {
    staticClass: "modal-content pb-3"
  }, [_c('div', {
    staticClass: "modal-body m-0 p-0"
  }, [_c('div', {
    staticClass: "mt-3"
  }, [_c('div', {
    staticClass: "col-md-12 mb-2 mt-5 mb-5"
  }, [_c('p', {
    staticClass: "text-center"
  }, [_vm._v(" " + _vm._s(_vm.selectedStatus == 0 ? 'Are you sure you want to enable API?' : 'Are you sure you want to disable API ? ') + " ")])]), _c('div', {
    staticClass: "col-md-12"
  }, [_vm.error ? _c('Alert', {
    attrs: {
      "message": _vm.toggleStatusError,
      "type": _vm.type
    }
  }) : _vm._e()], 1)]), _c('div', {
    staticClass: "modal__footer__btns d-flex justify-content-between mx-3"
  }, [_c('button', {
    staticClass: "cancel",
    staticStyle: {
      "width": "123px"
    },
    on: {
      "click": _vm.hideToggleModal
    }
  }, [_vm._v(" Cancel ")]), _c('el-button', {
    staticStyle: {
      "width": "123px"
    },
    attrs: {
      "type": "primary",
      "loading": _vm.toggleLoading,
      "id": "cancelinput"
    },
    on: {
      "click": _vm.toggleStatus
    }
  }, [_vm._v(" " + _vm._s(_vm.selectedStatus == 0 ? "Enable" : "Disable") + " ")])], 1)])]) : _vm._e()])])], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "mnotify-card-header d-flex justify-content-between align-items-center"
  }, [_c('h3', {
    staticClass: "mnotify-card-header-text"
  }, [_vm._v("API v.2.0")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('thead', {
    staticClass: "__mnotify__table__header"
  }, [_c('tr', [_c('th', {
    staticClass: "__mnotify__table__header_first",
    staticStyle: {
      "width": "15%"
    }
  }, [_vm._v(" Project Name ")]), _c('th', {
    staticStyle: {
      "width": "20%"
    }
  }, [_vm._v("Key")]), _c('th', {
    staticStyle: {
      "width": "15%"
    }
  }, [_vm._v("Date Created")]), _c('th', {
    staticStyle: {
      "width": "15%"
    }
  }, [_vm._v("Status")]), _c('th', {
    staticStyle: {
      "width": "15%"
    }
  }, [_vm._v("Usage Limit")]), _c('th', {
    staticStyle: {
      "width": "20%"
    }
  }, [_vm._v("Actions")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "col-md-12 __api_text"
  }, [_c('p', [_vm._v(" Visit the "), _c('a', {
    staticClass: "_visit",
    attrs: {
      "target": "_blank",
      "href": "https://readthedocs.mnotify.com/"
    }
  }, [_vm._v("documentation page")]), _vm._v(" to read about the API v.2.0 ")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "modal-header pt-4 pb-2"
  }, [_c('h3', {
    staticClass: "contact_detail_header"
  }, [_vm._v("Update Usage Limit")])]);
}];
export { render, staticRenderFns };